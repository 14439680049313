<template>
<div>
  <equipment-log-history :logEntries="log" />
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EquipmentLogHistory from '../components/equipmentLogHistory.vue';

export default {
  components: { EquipmentLogHistory },
  computed: {
    ...mapGetters('equipmentLog', ['log']),
  },
  methods: {
    ...mapActions('equipmentLog', ['resetState']),
  },
  created() {
    this.resetState();
  },
};
</script>
